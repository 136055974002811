<template>
	<div id="gradelist-wrapper">
		<p>清华大学马约翰杯{{ this.currentyear }}-{{ this.currentyear + 1 }}赛季</p>
		<div>
			<el-date-picker
				v-model="tableYear"
				clearable
				value-format="yyyy"
				type="year"
				@change="changeYear()"
				editable
				placeholder="选择年份，默认当前赛季"
				:default-value="this.currentdate"
			>
			</el-date-picker>
			<el-radio v-model="changeGradeShow" label="0" border>全部成绩</el-radio>
			<el-radio v-model="changeGradeShow" label="1" border>甲组成绩</el-radio>
			<el-radio v-model="changeGradeShow" label="2" border>乙组成绩</el-radio>
			<el-link
				target="tempIframe"
				:href="downloadUrl"
				:underline="false"
				style="margin: 15px"
			>
				<el-button type="primary" v-if="ableToDownload"
					><i class="el-icon-download"></i> 导出此赛季成绩(Excel)</el-button
				>
			</el-link>
			<el-button type="warning" v-if="ableToDownload" @click="modifyDialogVisible = true"
				><i class="el-icon-edit"></i> 修改项目成绩</el-button
			>
			<el-dialog
				title="修改比赛成绩"
				:visible.sync="modifyDialogVisible"
				width="30%">
				<el-form :model="modifyTarget">
					<el-form-item label="院系" :label-width="formLabelWidth">
						<el-select v-model="modifyTarget.dept" filterable placeholder="请选择院系">
							<el-option
								v-for="item in deptList"
								:key="item.deptid"
								:label="item.deptname"
								:value="item.deptid"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="比赛项目" :label-width="formLabelWidth">
						<el-select v-model="modifyTarget.game" filterable placeholder="请选择比赛">
							<el-option
								v-for="(item, index) in this.items"
								:key="index"
								:label="item"
								:value="item"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="得分修改为" :label-width="formLabelWidth">
						<el-input v-model="modifyTarget.score" autocomplete="off"
						placeholder="请输入分数"
						style="width: 40%;"></el-input>
					</el-form-item>
					<el-form-item label="名次修改为" :label-width="formLabelWidth">
						<el-input v-model="modifyTarget.rank" autocomplete="off"
						placeholder="请输入名次"
						style="width: 40%;"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="modifyDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="submitEdit()">确 定</el-button>
				</div>
			</el-dialog>
		</div>
		<div id="roll-notice">
			<span>若表格太宽而无法完全显示，请左右滑动查看</span>
		</div>
		<el-table
			id="gradelist-table"
			:data="tableData"
			border
			:row-class-name="tableRowClassName"
			max-height="600px"
			:default-sort="{ prop: 'total', order: 'descending' }"
			style="width: 100%; margin-top: 20px"
		>
			<el-table-column
				fixed
				prop="rank"
				label="名次"
				width="50px"
				v-if="showRank"
			/>
			<el-table-column fixed prop="deptname" label="院系" width="150px" />
			<el-table-column
				fixed
				prop="totalscore"
				label="总分"
				sortable
				width="80px"
			/>

			<el-table-column
				v-for="(item, index) in this.items"
				:key="index"
				:label="item"
				width="180px"
			>
				<!-- <el-table-column
				v-for="(item, index) in this.items"
				prop="scores.score"
				sortable
				:sort-method="scoreCompare"
				:key="index"
				:label="item"
			> -->
				<!-- 数据的遍历  scope.row就代表数据的每一个对象-->
				<template slot-scope="scope">
					<span>{{ getScore(scope.row, item) }}</span>
				</template>
			</el-table-column>
		</el-table>
		<iframe name="tempIframe" style="display: none"></iframe>
		<el-backtop>
			<i class="el-icon-caret-top"></i>
		</el-backtop>
	</div>
</template>
<style>
.el-table .my-dept {
    background: #c7f0b1;
}
.el-table .even-dept {
    background: #fafcf9;
}
#gradelist-wrapper i {
	font-size: 16px;
}
.el-radio {
	margin: 0 20px 0 20px;
}
.el-date-editor {
	margin: 0 20px 0 0;
}
#roll-notice {
	margin-top: 15px;
	color: grey;
	font-size: small;
}
#gradelist-wrapper {
	height: 100%;
	width: 100%;
}
#gradelist-table {
	width: 90%;
	margin: 0 auto;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
	name: "GradeList",
	data() {
		return {
			items: [],
			currentyear: 0,
			currentdate: null,
			tableYear: null,
			tableData1: [],
			tableData2: [],
			tableData: [],
			changeGradeShow: "0",
			showRank: 0,
			downloadUrl: "/api/stat/download",
			ableToDownload: 0,
			username: '',
			modifyDialogVisible: false,
			deptList: [],
			modifyTarget: {
				dept: '',
				game: '',
				score: 0,
				rank: 0,
			},
			formLabelWidth: '120px',
		};
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		tableRowClassName({row, rowIndex}) {
			if (row.deptname === this.username) {
				return 'my-dept';
			}
			else if (rowIndex % 2 === 0) {
				return 'even-dept';
			}
			return '';
		},
		submitEdit() {
			console.log(this.modifyTarget)
			axios({
				url: "/api/admin/result/modify",
				method: "POST",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
				data: qs.stringify(this.modifyTarget),
			})
			.then(() => {
				this.messageBox(
					"修改成功",
					"success"
				);
				this.modifyDialogVisible = false;
                this.$router.go(0);
			})
			.catch((err) => {
				this.messageBox(
					"修改失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log(err.response);
			});
		},
		changeYear() {
			if (this.tableYear === null) return;
			this.currentyear = Number(this.tableYear);
			this.getStatOf(this.tableYear);
			this.downloadUrl = "/api/stat/download?year=" + String(this.tableYear);
		},
		getStatOf(year = null) {
			// Return the statistics of the input param, current year if param is null
			var getYearUrl =
				year == null ? "/api/stat/get" : "/api/stat/get?year=" + String(year);
			axios({
				url: getYearUrl,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
				.then((res) => {
					this.tableData1 = res.data.data1;
					this.tableData2 = res.data.data2;
					this.getTabledata();
				})
				.catch((err) => {
					this.messageBox(
						"获取赛季成绩失败， " + err?.response?.data?.Message ??
							"网络断开或神秘错误",
						"error"
					);
					console.log("Get scores statistics Failed");
					console.log(err.response);
					return -1;
				});
		},
		getTabledata() {
			this.tableData = [];
			for (let itemdept of this.tableData1)
				for (let gameitem of itemdept.scores)
					if (this.items.indexOf(gameitem.item) === -1)
						this.items.push(gameitem.item);

			for (let itemdept of this.tableData2)
				for (let gameitem of itemdept.scores)
					if (this.items.indexOf(gameitem.item) === -1)
						this.items.push(gameitem.item);
			this.tableData = this.tableData
				.concat(this.tableData1)
				.concat(this.tableData2);
		},
		getScore(deptitem, gameitem) {
			for (let i of deptitem.scores) {
				if (i.item === gameitem) {
					let scoreString = String(i.score) + " （";
					scoreString += "排名" + String(i.rank);
					if (i.select === 0) {
						scoreString += " 未选项）";
					} else {
						scoreString += "）";
					}
					return scoreString;
				}
			}
			return "--";
		},
		// scoreConvert(scoreStr) {
		// 	if (scoreStr == '--') {
		// 		return -1;
		// 	}
		// 	else {
		// 		return isNaN(Number.parseInt(scoreStr)) ? -2: Number.parseInt(scoreStr);
		// 	}
		// },
		// scoreCompare(a, b) {
		// 	return this.scoreConvert(a) - this.scoreConvert(b);
		// },
	},
	mounted() {
		this.getStatOf();
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
			axios({
				url: "/api/game/getyear",
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
				.then((res) => {
					this.currentyear = res.data.year;
					console.log("current date is :");
					console.log(this.currentyear);
					this.currentdate = new Date().setFullYear(this.currentyear);
					console.log(this.currentdate);
				})
				.catch((err) => {
					this.messageBox(
						"获取当前赛季失败， " + err?.response?.data?.Message ??
							"网络断开或神秘错误",
						"error"
					);
					console.log("Get current year Failed");
					console.log(err.response);
				});
			axios({
				url: "/api/hello-user",
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
			.then((res) => {
				if (res.data.usertype === 'admin') {
					this.ableToDownload = 1;
				}
				if (res.data.usertype === 'dept') {
					this.username = res.data.username
				}
			})
			.catch((err) => {
				this.messageBox(
					"获取身份信息失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log(err.response);
			});
			axios({
				url: "/api/admin/asso/get?type=dept",
				method: "GET",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				this.deptList = res.data.data;
			})
			.catch((err) => {
				this.messageBox(
					"获取协会列表失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log("Get deptList Failed");
				console.log(err.response);
			});
		}
	},

	watch: {
		changeGradeShow(val) {
			this.tableData = [];
			switch (val) {
				case "0":
					this.tableData = this.tableData
						.concat(this.tableData1)
						.concat(this.tableData2);
					this.showRank = 0;
					break;
				case "1":
					this.tableData = this.tableData1;
					this.showRank = 1;
					break;
				case "2":
					this.tableData = this.tableData2;
					this.showRank = 1;
					break;
				default:
					this.messageBox("切换组别出现错误", "error");
			}
		},
	},
};
</script>
